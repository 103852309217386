import { StandardAuditableDto } from '../../standard-api';

export enum TagType {
  PRODUCT = 'PRODUCT', CATEGORY = 'CATEGORY'
}

export interface Tag extends StandardAuditableDto {
  type?: TagType;
  value?: string;
  translations?: any;
}
