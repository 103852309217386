import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Product } from '../api/core/products/product';
import { ProductModalComponent } from './product-modal/product-modal.component';
import { StoreModalComponent } from './store-modal/store-modal.component';
import { Store } from '../api/stores/stores/store';
import { User } from '../api/stores/users/user';
import { UserModalComponent } from './user-modal/user-modal.component';
import { ProductVersion } from '../api/core/products/versions/product-version';
import { ProductVersionModalComponent } from './product-version-modal/product-version-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  constructor(private modals: NgbModal) {
  }

  store(store: Store = {}): Promise<any> {
    const modalRef = this.modals.open(StoreModalComponent, {
      centered: true
    });
    modalRef.componentInstance.store = store;
    return modalRef.result;
  }

  user(user: User = {}, disabledFields: { [name: string]: boolean } = {}): Promise<any> {
    const modalRef = this.modals.open(UserModalComponent, {
      centered: true
    });
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.disabledFields = disabledFields;
    return modalRef.result;
  }

  product(product: Product = {}): Promise<any> {
    const modalRef = this.modals.open(ProductModalComponent, {
      centered: true
    });
    modalRef.componentInstance.product = product;
    return modalRef.result;
  }

  productVersion(product: Product = {}, version: ProductVersion = {}): Promise<any> {
    const modalRef = this.modals.open(ProductVersionModalComponent, {
      centered: true
    });
    modalRef.componentInstance.product = product;
    modalRef.componentInstance.version = version;
    return modalRef.result;
  }
}
