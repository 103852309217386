import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmModalComponent, ConfirmModalOptions } from './confirm-modal/confirm-modal.component';
import { AssetsModalComponent } from './assets-modal/assets-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UtilModalsService {

  constructor(private modals: NgbModal) {
  }

  confirm(title: string, message: string): Promise<boolean> {
    const options: ConfirmModalOptions = {
      title,
      text: message
    };
    const modalRef = this.modals.open(ConfirmModalComponent, {
      centered: true
    });
    modalRef.componentInstance.options = options;
    return modalRef.result.then(() => true).catch(() => Promise.resolve(false));
  }

  assets(multiple: boolean = false): Promise<any> {
    const modalRef = this.modals.open(AssetsModalComponent, {
      centered: true,
      size: 'xl'
    });
    modalRef.componentInstance.multiple = multiple;
    return modalRef.result;
  }
}
